<template>
  <a-result status="success">
    <h1 slot="title">
      {{ $t(`msg.shipment.success.${$route.query.type}`) }}
    </h1>
    <div slot="subTitle">
      <h4 class="text-secondary p-2">
        {{$t('code')}}: <span class="badge bg-default text-dark text-monospace p-2">{{ $route.query.code }}</span>
      </h4>
    </div>
    <template #extra>
      <router-link :to="`/shipments/${$route.query.code}/detail`">
        <a-button
          type="primary"
          size="large">
          {{ $t('msg.shipment.success.action1') }}
        </a-button>
      </router-link>
      <router-link :to="`/shipments`">
        <a-button
          type="link"
          size="large">
          {{ $t('msg.shipment.success.action2') }}
        </a-button>
      </router-link>
    </template>
  </a-result>
</template>

<script>
export default {
  name: 'ShipmentResult',
  props: ['result'],
}
</script>
